import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import dotenv from 'dotenv';

// Cargar las variables de entorno desde el archivo .env
dotenv.config();

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
};

// Inicializa Firebase si no hay ninguna app inicializada
export const app = !getApps().length ? initializeApp(firebaseConfig) : getApps()[0];

// Obtener la instancia de autenticación
export const auth = getAuth(app);