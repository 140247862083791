<template>
  <div
    class="h-full w-full text-left flex flex-col"
    :class="mt_disabled ? '' : 'mt-5'"
  >
    <label
      :for="id"
      class="text-xs md:text-xs text-gray-500 text-opacity-80"
      :class="{ 'text-red-500': invalid }"
      ><b>{{ label }}</b
      ><span v-if="required" class="text-red-500">*</span></label
    >
    <span
      v-if="subtitle"
      class="mb-2 text-xs md:text-xs text-gray-600 text-opacity-80 text-gray-500"
      >{{ subtitle }}</span
    >
    <input
      class="w-full mt-2 border rounded-lg text-xs md:text-sm"
      :class="classObject"
      type="number"
      :id="id"
      :name="id"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keyup="checkInput"
      @change="$emit('notify-change', $event.target.value)"
      @keypress="validateInput"
      autocomplete="on"
      :disabled="disabled"
      :max="maxLength"
    />
    <p v-if="invalid" class="mt-2 text-xs text-red-500">
      {{ feedback ? feedback : "Este campo es obligatorio" }}
    </p>
  </div>
</template>

<script>
export default {
  props: [
    "id",
    "type",
    "label",
    "subtitle",
    "placeholder",
    "value",
    "disabled",
    "danger",
    "required",
    "maxLength",
    "mt_disabled",
    "validatePositiveInteger",
  ],
  data() {
    return {
      feedback: "",
      invalid: false,
    };
  },
  mounted() {},
  methods: {
    checkInput() {
      if (this.value == "" && this.required) {
        this.invalid = true;
        this.feedback = "Este campo es obligatorio";
      } else {
        this.invalid = false;
        this.feedback = "";
      }
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid;
      }
    },
    validateInput(event) {
      if (this.validatePositiveInteger) {
        const char = String.fromCharCode(event.keyCode);
        if (!/^[0-9]+$/.test(char)) {
          event.preventDefault();
        }
      }
    },
  },
  computed: {
    classObject: function () {
      let classes = ["border-gray-300", "placeholder-gray-400"];
      if (this.danger || this.invalid) {
        classes = ["border-red-500", "placeholder-red-500"];
      }
      return classes;
    },
  },
};
</script>
