<template>
  <div class="w-full mt-5 flex flex-col border-0 p-0">
    <label :for="id" v-if="label" class="mb-2 text-xs md:text-xs text-left text-gray-500 text-opacity-80"
      :class="{ 'text-red-500': invalid }"><b>{{ label }}</b><span v-if="required" class="text-red-500">*</span></label>
    <p v-if="sublabel" class="text-xs text-gray-400 text-left mb-2 ">
      {{ sublabel }}
    </p>
    <a-select :disabled="disabled" :mode="mode" size="large" :placeholder="placeholder ? placeholder : 'Seleccionar'"
      show-search option-filter-prop="children" :class="{ 'border-red-500': invalid }" class="uppercase"
      :filter-option="filterOption" :value="value" @change="handleChange" :loading="loading">
      <a-select-option value="" class="text-gray-400 font-normal">
        Seleccionar
      </a-select-option>
      <a-select-option data-bs-toggle="tooltip" :title="option.name" v-for="option in selectOptions"
        style="text-align: center;" :value="`${option.id}`" :key="option.id" class="uppercase">
        {{ option.name }}
      </a-select-option>
    </a-select>

    <p v-if="invalid" class="mt-2 text-left text-xs text-red-500">
      Este campo es obligatorio
    </p>
    <Modal v-if="showModal" size="md">
      <div class="text-center">
        <p class="mt-4" v-html="modalText"></p>
        <button @click="closeModal" class="mt-4 py-2 px-4 bg-blue-500 text-white rounded-lg">ACEPTAR</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/UI/Modal.vue";

export default {
  components: {
    Modal,
  },
  props: [
    "id",
    "selectOptions",
    "label",
    "required",
    "value",
    "mode",
    "placeholder",
    "loading",
    "disabled",
    "getObjectValue",
    "sublabel"
  ],
  data() {
    return {
      invalid: false,
      showModal: false,
      modalText: ''
    };
  },
  methods: {
    handleChange(value) {
      const selectedOption = this.selectOptions.find(option => option.id == value);    
      if (selectedOption && selectedOption.popup_show) {      
        this.showModal = true;
        this.modalText = selectedOption.popup_text;
      }
      if (this.getObjectValue) {
        this.$emit("change", this.selectOptions.find(option => option.id == value));
        this.$emit("input", value);
      }
      else {
        this.$emit("input", value);
        this.$emit("change", value);
      }
      this.$emit('response', this.selectOptions.find(option => option.id == value))
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid;
      }
    },
    closeModal() {
      this.showModal = false;
    }
  },
  watch: {
    value: function (value) {
      if (value == "" && this.required) {
        this.invalid = true;
      } else {
        this.invalid = false;
      }
    },
  },
};
</script>

<style scoped>
.ant-select-selection__placeholder {
  text-align: center !important;
}

.ant-select-selection-selected-value {
  text-align: center !important;
  float: none !important;
}

.ant-select-selection {
  float: none !important;
  text-align: center !important;
  justify-content: center !important;
}

.ant-select-selection--single {
  height: 40px;
}
</style>
