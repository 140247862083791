<template>
    <div class="">
        <h3 class="text-lg sm:text-lg text-blue-dark font-bold">Ingresar código de autenticación.</h3>
        <h3 class="text-lg sm:text-lg">{{ label }}</h3>
        <a-form-model layout="vertical">
            <a-input class="mt-5" :placeholder=placeholderInput autocomplete="" v-model="phoneNumber"
                ref='phoneNumber' />
            <button id="sign-in-button" @click="sendVerificationCode">Send Code</button>
        </a-form-model>
    </div>
</template>

<script>

import { RecaptchaVerifier, PhoneAuthProvider, signInWithCredential, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../firebase/config";

export default {
    props: ['label'],
    data() {
        return {
            phoneNumber: '',
            verificationCode: '',
            placeholderInput: 'Ingrese aquí el código'
        };
    },
    created() {
    },
    components: {
    },
    methods: {
        async verifyCode() {
            this.error = null;
            try {
                if (!this.verificationCode) throw new Error("Please enter the verification code.");
                const credential = PhoneAuthProvider.credential(this.verificationId, this.verificationCode);
                const userCredential = await signInWithCredential(auth, credential);
                this.user = userCredential.user;
                this.verificationCodeSent = false;
                console.log("User authenticated:", this.user.phoneNumber);
            } catch (err) {
                console.error("Error al verificar el código:", err);
                this.error = err.message;
            }
        }
    },
    computed: {
    }
}
</script>

<style></style>