<template>
    <div>
        <a-modal v-model="this.visible" :maskClosable="false" :closable="false">
            <slot></slot>
            <template slot="footer">
                <div class="modal-footer mt-2">
                    <a-button class="w-full" type="danger" shape="round" ghost @click="this.handleCancel">
                        CANCELAR
                    </a-button>
                    <a-button class="w-full" type="primary" shape="round" @click="this.handleOk"
                        :disabled="this.modalProps.disabledOkButton">
                        ENVIAR
                    </a-button>
                </div>
                <div v-if="this.showResendCode" class="retry mt-5">
                    <p class="text-sm font-medium mb-1">¿No recibió el código?</p>
                    <p class="text-blue font-bold text-sm cursor-pointer mb-3" @click="this.handleResendCode">Enviar el código nuevamente</p>                     
                </div>

            </template>


        </a-modal>
    </div>
</template>
<script>
export default {
    props: ['visible', 'handleOk', 'handleCancel','showResendCode', 'handleResendCode','modalProps'],
    data() {
        return {
            isvisible: this.visible
        };
    },
    methods: {
    },
};
</script>

<style scoped>
.modal-footer {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 0 auto;
}

.retry {
    display: flex;
    flex-direction: column;     
    align-items: center;
    justify-content: center;
}
</style>
